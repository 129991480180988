/* .nav-treeview li::before{
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    background-color: #f2f2f2;
} */

.nav-treeview li a {
    display: flex;
}

.nav-sidebar .nav-treeview {

    padding: 0;
}

.nav-link {
    display: inline-block;

} 
.nav-sidebar>.nav-item .nav-icon {
    margin-left: 0.05rem;
    font-size: 1.2rem;
    margin-right: 0.2rem;
    text-align: center;
    width: 1.6rem;
}
 .nav-icon {
    margin-left: 0.05rem;
    font-size: 1.2rem;
    margin-right: 0.2rem;
    text-align: center;
    width: 1.6rem;
}
.right {
    position: absolute;
    right: 200rem;
    top: 0.7rem;
}


.link:hover{
    background: none!important;
}



.link p::before{
    position: absolute;
    content: "";
    color: blueviolet;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    left: 19px;
}

.active p::before{
    background-color: black;
}