.contenedor {
  font-family: "Roboto", "Lucida Grande", Verdana, Arial, sans-serif;
}
.contenedor h1 {
  font-size: 12pt;
  text-align: center;
  margin-bottom: 20px;
}
.contenedor th {
  padding: 4px;
  text-align: left;
  font-size: 8pt;
}
.contenedor tr, .contenedor td {
  padding: 4px;
  text-align: left; 
  font-size: 9pt;
}
.contenedor th, .contenedor td {
  padding: 4px;
  text-align: left;
  font-size: 8pt;
}
.contenedor th {
  background-color: #dddddd;
}
.contenedor header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 20px;
}
.contenedor table {
  border-collapse: collapse;
  width: 100%;
}

#tabla_encabezado {
  border-top-left-radius: 20px;
  padding: 1em;
  border: 1px solid black;
}

.titulo_tiket {
  background-color: #6c757d;
  color: #ffffff;
  text-align: center;
  font-size: 10pt;
}

.sub_titulo_tiket {
  background-color: #ffffff;
  color: #ffffff;
  text-align: center;
  font-size: 8pt;
}

.h1_sub_titulo {
  font-size: 8pt;
  text-align: center;
  margin: 0;
}

#cont_tiket {
  padding: 1em;
  border: 1px solid rgba(0, 0, 0, 0.212);
}

#logo_syzapi {
  text-align: right;
  width: 35%;
}

#logo_cliente {
  text-align: left;
  width: 35%;
}

#header-table {
  width: 100%;
  border-collapse: collapse;
}

.header-title {
  text-align: left;
  padding: 10px;
  background-color: #f2f2f2;
}

.header-logo {
  text-align: right;
  padding: 10px;
  background-color: #f2f2f2;
}

.company-logo {
  width: 100px;
  height: auto;
}

.table tbody tr:hover {
  background-color: #f5f5f5;
}

.numeric {
  text-align: right;
}

@media only screen and (max-width: 600px) {
  table {
    width: 100%;
  }

  th, td {
    font-size: 10pt;
    padding: 6px;
  }
}

@page {
  margin: 10mm 10mm 10mm 10mm;
}
