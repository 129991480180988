.custom-data-grid {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .react-data-grid-header {
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
  }
  
  .react-data-grid-header .react-data-grid-cell {
    font-weight: bold;
    color: #666;
  }
  
  .react-data-grid-row {
    background-color: #fff;
  }
  
  .react-data-grid-row:hover {
    background-color: #f5f5f5;
  }
  
  .react-data-grid-cell {
    padding: 8px;
  }
  
  .react-data-grid-cell-center {
    text-align: center;
  }
  
  .react-data-grid-cell-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  