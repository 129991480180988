.paginaAviso {
    background-image: url('./FONDB.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    
.paginaAviso-content {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    }


.error-message {
        color: #dc3545;
        font-size: 14px;
        margin-top: 5px;
        padding: 5px 10px;
        border: 1px solid #dc3545;
        border-radius: 5px;
      }