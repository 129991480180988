.datepicker-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .form-control {
    padding: 0.5rem;
    border-radius: 0.3rem;
    border: 1px solid #d4d4d5;
    box-shadow: none;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    color: #fff;
    border-radius: 0.3rem;
    margin-top: 1rem;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
    color: #fff;
  }
  