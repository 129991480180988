.row-auto {
    background-image: url("../../../assets/fondo.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
}

.contenido_form {
    border-radius: 40px 0px 40px 0px;
    border: 3px solid;
    padding: 10px;
}

.login-container {
    border: 1px solid black;
    margin-top: 200px;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    position: relative;
    width: 100%;
    padding-right: 7.5px;
    padding-left: 7.5px;
    margin-left: 20%;
    border-radius: 40px 0px 40px 0px;
   
}


.input-group-text {
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0PERSONAS.25rem;
}


.far {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 400;
    font-family: "Font Awesome 5 Free";
}

.bloque-centrado {
    margin: 100px 0 0 0;
}

.login_logo {
    position: absolute;
    left: 20px;
    top: 10px;
}

.bg-dark, .bg-dark>a {
    color: #fff!important;
}

.bg-dark {
    background-color: #343a40!important;
}

.text-wrap {
    white-space: normal!important;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.text-center {
    text-align: center!important;
}

.text-danger {
    color: #dc3545!important;
}

.credencial {
    margin-top: -80px;
    margin-left: 140px;
}

.input-group-text{
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: flex;

}

.position-relative {
    position: relative!important;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.float-right {
    float: right!important;
}

.main-sidebar, .main-sidebar::before {
    width: 250px;
}

.sec_container {
    padding: 105px 15px 15px;
}

.footer {
    width: 100%;
    position: fixed;
    bottom: 0;
}